import React, { FC } from 'react';
import './FullCredits.scss';
import SectionTitle from '../../../components/common/SectionTitle';
import Span from '../../../components/pagesComponents/CaseStudy/Span';
import StatfloLayout from '../../../components/PagesLayouts/StatfloLayout';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import FixedButton from '../../../components/common/FixedButton';

import { TNamesArray } from '../../../types/types';
import { useScrollTo } from '../../../hooks/useScrollToTop';
import { useGetScreenWidth } from '../../../hooks/useGetScreenWidth';

const FullCredits: FC = () => {
    const clientsArray: TNamesArray = [
        { id: 0, name: 'Ian Gervais, ', position: 'VP of Product', subtype: 'ml-5' },
        { id: 1, name: 'Yan Chin, ', position: 'Product Owner', subtype: 'ml-5' },
        {
            id: 2,
            name: 'Guilherme Blanco, ',
            position: 'Former VP of Engineering',
            subtype: 'ml-5',
        },
        { id: 3, name: 'Jeff Wilde, ', position: 'Solution Architect', subtype: 'ml-5' },
    ];

    const developersArray: TNamesArray = [
        {
            id: 0,
            name: 'Dmytro Zakharchenko, ',
            position: 'Sr. Software Engineer',
            subtype: 'ml-5',
        },
        { id: 1, name: 'Anton Shloma, ', position: 'Engineer', subtype: 'ml-5' },
        { id: 2, name: 'Max Gritsyk,  ', position: 'Engineer', subtype: 'ml-5' },
        { id: 3, name: 'Zhenya Ryzhov, ', position: 'Sr. Software Engineer', subtype: 'ml-5' },
        { id: 4, name: 'Ievgeniy Gerasimenko, Engineer', position: 'Engineer', subtype: 'ml-5' },
        { id: 5, name: 'Ivan Saveliev,', position: 'Engineer', subtype: 'ml-5' },
    ];

    const employeesArray: TNamesArray = [
        { id: 0, name: 'Taras Savytskyi,', position: 'Senior Design Evangelist', subtype: 'ml-5' },
        { id: 1, name: 'Eric Song,', position: 'QA Lead', subtype: 'ml-5' },
    ];

    const managersArray: TNamesArray = [
        { id: 0, name: 'Yurii Drozd,', position: 'CEO', subtype: 'ml-5' },
    ];

    const qaArray: TNamesArray = [
        { id: 0, name: 'Dmitry Deleur,', position: 'Senior QA', subtype: 'ml-5' },
    ];

    const isMobile = useGetScreenWidth();
    const scrollValue = isMobile ? 1970 : 1100;

    useScrollTo(0, scrollValue);

    return (
        <StatfloLayout>
            <div className="scope-of-project__wrapper">
                <SectionTitle type="primary" title="Full credits" />
                <div className="scope-of-project__body">
                    <div className="scope-of-project__description scope-of-project__description--mt">
                        <div className="scope-of-project__item">
                            <SectionTitle subtype="mb-30" type="secondary" title="Statflo inc." />
                            <Span type="headline" subtype="mb-10">
                                Client managers
                            </Span>
                            {clientsArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}

                            <Span type="headline" subtype="m-10">
                                Client employees
                            </Span>
                            {employeesArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                        </div>

                        <div className="scope-of-project__item">
                            <SectionTitle type="secondary" subtype="mb-30" title="Blackbird" />
                            <Span type="headline" subtype="mb-10">
                                Managers
                            </Span>
                            {managersArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}

                            <Span type="headline" subtype="m-10">
                                Developers
                            </Span>
                            {developersArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}

                            <Span type="headline" subtype="m-10">
                                Quality control
                            </Span>
                            {qaArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Links />
                </div>
            </div>
            <FixedButton />
        </StatfloLayout>
    );
};

export default FullCredits;
